<template>
  <v-app>
    <v-main style="background-color: rgba(54,78,101,.8)">
      <v-row justify="center" align="center" class="w-100 h-100">
        <v-col cols="11" md="5">
          <v-card light>
            <v-img
                height="200"
                src="img/banner-1.png">
              <v-row class="h-100 pa-6" align="center" justify="center">
                <v-col class="pl-6">
                  <h3 class="headline white--text text-h3">GATE</h3>
                  <span class="grey--text text--lighten-1">Admin panel</span>
                </v-col>
              </v-row>
            </v-img>
            <v-form
                ref="loginForm"
                v-model="valid"
                lazy-validation>
              <v-container>
                <v-row>
                  <v-col cols="12" class="pb-0">
                    <v-text-field
                        :rules="requestField"
                        v-model="login"
                        color="blue-grey lighten-2"
                        label="Login"
                        required
                        outlined
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" class="pb-0 pt-0">
                    <v-text-field
                        type="password"
                        v-model="password"
                        :rules="requestField"
                        color="blue-grey lighten-2"
                        label="Password"
                        required
                        outlined
                        @keypress.enter="singIn"
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row justify="center">
                  <v-btn
                      :loading="loading"
                      :disabled="loading"
                      color="blue accent-4"
                      class="ma-2 white--text"
                      @click="singIn"
                  >Login<v-icon right dark>mdi-login</v-icon>
                  </v-btn>
                </v-row>
              </v-container>
            </v-form>
          </v-card>
        </v-col>
      </v-row>
    </v-main>
  </v-app>
</template>
<script>
import TokenService from "../../services/TokenService";
import moment from "moment";
// import store from "../../store";
export default {
  data() {
    return {
      valid: false,
      loading: false,
      login: '',
      password: '',
    }
  },
  methods: {
    async singIn() {
      await this.validate();
      if (this.valid) {
        this.loading = true;
        try {
          const res = await this.$http.post('User/login', {
            username: this.login,
            password: this.password
          })
          TokenService.setToken(res.result.accessToken)
          TokenService.setRefreshToken(res.result.refreshToken)
          localStorage.setItem('expiredDate', moment.utc(res.result.expireDate).local().format());
          await this.$router.push({ name: 'clientSecond'});
        } catch (e) {
          console.log(e)
          this.errorNotification(e.response.data.message)
        } finally {
          this.loading = false;
        }
      }
    },
    validate() {
      this.$refs.loginForm.validate()
    },
    reset () {
      this.$refs.loginForm.reset()
    },
    resetValidation () {
      this.$refs.loginForm.resetValidation()
    },
  },
}
</script>
<style scoped>

</style>
